import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { BillsComponent } from "../Components/navbar/Billscomponent";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { Paymentmodal } from "../Components/PaymentModal/PaymentModal";
import { MdOutlineAccountCircle, MdOutlineRemoveRedEye, MdOutlineDownload, MdOutlineMailOutline, MdOutlinePhone, MdOutlineStore } from "react-icons/md";
import "./customerManagement.css"
import { TbUserCheck } from "react-icons/tb";
import { RiMapPin2Line, RiDeleteBin6Line } from "react-icons/ri"
import { useNavigate, useSearchParams } from "react-router-dom"
import { SiContactlesspayment } from "react-icons/si";
import Select from 'react-select';
import useDebouncedValue from "../Utils/Debounce";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";

const ClientBillV = () => {
    const [client_id, setClientId] = useState("")
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    let navigate = useNavigate();
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const HandleSearchDebounce = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/accounts/accountstatement/?search=${debouncedSearchTerm}&year_month=${filter_data.generated_date}`
            );
            SetData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('')
    const [rowId, setRowId] = useState(false)
    const [formData, setFormData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [customData, setCustomData] = useState("Hello from props!");
    function getCurrentMonth() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}`;

        return formattedDate;
    }

    const [filter_data, setfilter_data] = useState({
        generated_date: getCurrentMonth()
    })
    const handlFilterchange = (e) => {
        const { name, value } = e.target
        setfilter_data({ ...filter_data, [name]: value })
    }
    const handleShow = (row) => {
        setCustomData(row)
        setShowModal(true);
    }
    const [searchParams, setSearchParams] = useSearchParams();
    let month = searchParams.get("month")
    console.log(month)
    const handleClose = () => setShowModal(false);
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/accounts/accountstatement/?year_month=${filter_data.generated_date}&client=${client_id}&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };

    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/accountstatement/?year_month=${filter_data.generated_date}&client=${client_id}&page=${currentPage}&page_size=${RowPerPage}`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    const [Clients, setClients] = useState([])
    const GetDataClients = async () => {

        try {
            const responce = await axiosConfig.get(`/clients/clients/?page_size=1000&is_suspended=false`)
            setClients(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetDataClients();
        GetData();
        navigate(`/dashboard/masters/client_bills/?month=${filter_data.generated_date}`)
    }, [currentPage, RowPerPage, filter_data.generated_date, client_id, month]);
    useEffect(() => {
        HandleSearchDebounce();
    }, [debouncedSearchTerm])
    const [customerid, setCustomerid] = useState()
    const HandleMouse = (row) => {
        setRowId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setRowId()
    }
    const handleRowClick = (row) => {
        console.log(row)
        navigate(`/dashboard/masters/${row.client.id}/client_transactions`, { state: { rowData: row, year_month: filter_data.generated_date } });
    }
    const [isSelectVisible, setIsSelectVisible] = useState(false);
    const handleFilterIconClick = () => {
        setIsSelectVisible(!isSelectVisible);
    };
    const handleDownload = (row) => {
        row.account_statement_path ? window.open(row.account_statement_path) : alert("No file found")
    };
    const handleExcel = (row) => {
        const url = `${axiosConfig.defaults.baseURL}accounts/account_statement_pdf_to_csv/?period_from=${row?.period_from || ''}&period_to=${row?.period_to || ''}&client=${row?.client?.id || ''}`;
        window.open(url, '_blank');
    }
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "left"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "left",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    function formatTimestamp(inputTimestamp) {
        const dateObject = new Date(inputTimestamp);

        // Extract individual components
        const year = dateObject.getFullYear() % 100;
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        const hours = 23;
        const minutes = 59;
        const seconds = 0;

        // Format components into the desired string
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
            width: "80px",
        },
        {
            name: "Client",
            selector: "full_name",
            cell: row => <div className="client-detail">
                {/* <div className="client-logo" style={{ width: "82px", height: "82px" }}>
                    <img src={row.client.logo && row.client.logo.image} style={{ width: "100%", height: "100%" }} alt="logo" />
                </div> */}
                <div className="client-data">
                    <div className="client-name">{row.client.client_name}</div>
                    <div className={`client-type ${row.client.client_type === 'Contracted' ? 'contract-background' : 'variable-background'}`}>{row.client.client_type}</div>
                </div>

            </div>,
            sortable: true,
            width: "180px",
        },
        // {
        //     cell: row => <div className="store-info mx-4" >
        //         <div className="contact-details"><RiMapPin2Line className="contact-icon" />{row.client.address}</div>
        //         <div className="contact-email"><MdOutlineMailOutline className="contact-icon" />{row.client.email}</div>
        //         <div className="contact-phone"><MdOutlinePhone className="contact-icon" />{row.client.mobile}</div>
        //     </div>,
        //     sortable: true,
        //     width: "250px",
        // },
        {
            name: "Billing Period",
            selector: "generated_date",
            cell: row => `${row.period_from}-${row.period_to}`,
            sortable: true
        },
        {
            name: "Previous Month Due",
            selector: "previous_due",
            cell: row => `${row.previous_due}`,
            sortable: true
        },
        {
            name: "Payments Paid",
            selector: "payments",
            cell: row => `${row.payments}`,
            sortable: true
        },
        {
            name: "Total Purchase",
            selector: "date_joined",
            cell: row => `${row.purchase}`,
            sortable: true
        },
        {
            name: "Total Payment Due",
            selector: "total_amount_due",
            cell: row => `${row.total_amount_due}`,
            sortable: true
        },
        {
            name: "Due Date",
            selector: "duedate",
            cell: row => `${formatTimestamp(row.duedate)}`,
            sortable: true
        },
        {
            name: "Generated On",
            selector: row => {
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/clients/clients/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                return (
                    <>
                        {rowId === row.id ? <div className="row" >
                            <div className="col-3 pointer" onClick={() => handleDownload(row)}>
                                <AiOutlineFilePdf size={15} />
                            </div>
                            <div className="col-3 pointer" onClick={() => handleExcel(row)}>
                                <AiOutlineFileExcel size={15} />
                            </div>
                            <div className="col-3 pointer" onClick={() => handleRowClick(row)}>
                                <MdOutlineRemoveRedEye size={15} />
                            </div>
                            <div className="col-3 pointer">
                                <SiContactlesspayment size={15} onClick={() => handleShow(row)} />
                            </div>
                        </div> :
                            formatTimestamp(row.generated_date)
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <BillsComponent />
                    <div className="childnav-content">
                        <div className="row" >
                            <div className="col-6">
                                <div className="row">
                                    <div className=" col-3 form-group">
                                        <label>Account Statement</label>
                                    </div>
                                    <div className="col-6 form-group">
                                        <input type="month" className="form-control" name="generated_date" value={filter_data.generated_date} onChange={handlFilterchange} />
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex align-items-center gap-4">

                                    <div className="input-cat-with-icon">
                                        <span className="icon-product"><BiSearch /></span>
                                        <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group" style={{ zIndex: 1 }}>
                            <Select options={Clients.map((res) => (
                                { "value": res.id, "label": res.client_name }
                            ))} placeholder='Clients' onChange={(selectedOption) => setClientId(selectedOption.value)} />
                        </div>
                        <div className="table-responsive" style={{ zIndex: 0 }}>
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                fixedHeader
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                // onRowClicked={(row) => handleRowClick(row)}
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                highlightOnHover
                                paginationServer subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                        <div>
                            {popupType !== "" &&
                                <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} />
                            }
                            {deletePopupType !== "" &&
                                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                            }
                            {
                                showModal &&
                                <Paymentmodal showModal={showModal} handleClose={handleClose} customData={customData} formatTimestamp={formatTimestamp} GetData={GetData} />
                            }
                        </div>

                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}
export { ClientBillV }