import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { BillsComponent } from "../Components/navbar/Billscomponent";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { Paymentmodal } from "../Components/PaymentModal/PaymentModal";
import { MdOutlineAccountCircle, MdOutlineRemoveRedEye, MdDownload, MdFilterList, MdOutlineMailOutline, MdOutlinePhone, MdOutlineStore } from "react-icons/md";
import "./customerManagement.css"
import { TbUserCheck } from "react-icons/tb";
import { RiMapPin2Line, RiDeleteBin6Line } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import { SiContactlesspayment } from "react-icons/si";
import Select from 'react-select';
import useDebouncedValue from "../Utils/Debounce";

const PaymentInvoices = () => {
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    let navigate = useNavigate();
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const HandleSearchDebounce = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/accounts/payments/?search=${debouncedSearchTerm}&year_month=${filter_data.generated_date}`
            );
            SetData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('')
    const [rowId, setRowId] = useState(false)
    const [formData, setFormData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [customData, setCustomData] = useState("Hello from props!");
    function getCurrentMonth() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}`;

        return formattedDate;
    }

    const [filter_data, setfilter_data] = useState({
        generated_date: getCurrentMonth()
    })
    const handlFilterchange = (e) => {
        const { name, value } = e.target
        setfilter_data({ ...filter_data, [name]: value })
    }
    const handleShow = (row) => {
        setCustomData(row)
        setShowModal(true);
    }
    const handleClose = () => setShowModal(false);
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/accounts/payments/?year_month=${filter_data.generated_date}&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };

    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/payments/?year_month=${filter_data.generated_date}&page=${currentPage}&page_size=${RowPerPage}`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, filter_data.generated_date]);
    useEffect(() => {
        HandleSearchDebounce();
    }, [debouncedSearchTerm])
    const [customerid, setCustomerid] = useState()
    const HandleMouse = (row) => {
        setRowId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setRowId()
    }
    const handleRowClick = (row) => {
        console.log(row)
        navigate(`/dashboard/masters/${row.client.id}/client_transactions`, { state: { rowData: row } });
    }
    const [isSelectVisible, setIsSelectVisible] = useState(false);
    const handleFilterIconClick = () => {
        setIsSelectVisible(!isSelectVisible);
    };
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "left"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "left",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    function formatTimestamp(inputTimestamp) {
        const dateObject = new Date(inputTimestamp);

        // Extract individual components
        const year = dateObject.getFullYear() % 100;
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        const hours = 23;
        const minutes = 59;
        const seconds = 0;

        // Format components into the desired string
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Invoice No.",
            selector: "invoice_no.",
            cell: row => `${row.invoice_no}`,
            sortable: true
        },
        {
            name: "Client",
            selector: "full_name",
            cell: row => <div className="client-detail">
                {/* <div className="client-logo" style={{ width: "82px", height: "82px" }}>
                    <img src={row.client.logo && row.client.logo.image} style={{ width: "100%", height: "100%" }} alt="logo" />
                </div> */}
                <div className="client-data">
                    <div className="client-name">{row.client.client_name}</div>
                    <div className={`client-type ${row.client.client_type === 'Contracted' ? 'contract-background' : 'variable-background'}`}>{row.client.client_type}</div>
                </div>

            </div>,
            sortable: true
        },
        // {
        //     cell: row => <div className="store-info mx-4" >
        //         <div className="contact-details"><RiMapPin2Line className="contact-icon" />{row.client.address}</div>
        //         <div className="contact-email"><MdOutlineMailOutline className="contact-icon" />{row.client.email}</div>
        //         <div className="contact-phone"><MdOutlinePhone className="contact-icon" />{row.client.mobile}</div>
        //     </div>,
        //     sortable: true,
        //     width: "250px",
        // },
        {
            name: "Date",
            selector: "datetime",
            cell: row => `${formatTimestamp(row.datetime)}`,
            sortable: true
        },
        {
            name: "Amount",
            selector: row => {
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/clients/clients/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                function downloadPDF(row) {
                    var link = document.createElement('a');
                    link.href = row.payment_receipt_path;
                    link.target = '_blank';
                    link.download = `${row.id}.pdf`;

                    // Appending the link to the body is necessary for some browsers
                    document.body.appendChild(link);

                    // Triggering the click event
                    link.click();

                    // Removing the link from the body to clean up after the download
                    document.body.removeChild(link);
                }
                return (
                    <>
                        {rowId === row.id ? <div className="row" >
                            {/* <div className="col-6">
                                <SiContactlesspayment size={15} onClick={() => handleShow(row)} />
                            </div> */}
                            <div className="col-6">
                                <MdDownload size={15} onClick={() => downloadPDF(row)} />
                            </div>
                        </div> :
                            `₹${row.amount}`
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <BillsComponent />
                    <div className="childnav-content">
                        <div className="row" >
                            <div className="col-6">
                                <div className="col-6 form-group">
                                    <input type="month" className="form-control" name="generated_date" value={filter_data.generated_date} onChange={handlFilterchange} />
                                </div>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <div className="input-cat-with-icon">
                                    <span className="icon-product"><BiSearch /></span>
                                    <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive marginTop">
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                // onRowClicked={(row) => handleRowClick(row)}
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                highlightOnHover
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                        <div>
                            {popupType !== "" &&
                                <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} />
                            }
                            {deletePopupType !== "" &&
                                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                            }
                            {
                                showModal &&
                                <Paymentmodal showModal={showModal} handleClose={handleClose} customData={customData} formatTimestamp={formatTimestamp} />
                            }
                        </div>

                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}
export { PaymentInvoices }