import React, { useEffect, useState } from "react";
import { DashboardLayout, PopupModal } from "../Components";
import { BillsComponent } from "../Components/navbar";
import { BiSearch } from "react-icons/bi";
import Select from 'react-select';
import { useNavigate } from "react-router";
import axiosConfig from '../Service/axiosConfig';
import DataTable from 'react-data-table-component';
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { FaHandHoldingHeart } from "react-icons/fa";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import useDebouncedValue from "../Utils/Debounce";
import { AiOutlineFileExcel } from "react-icons/ai";

const GRN = () => {
    const navigate = useNavigate();
    const [grns, setGrns] = useState([]);
    const [vendors, setVendors] = useState([])
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [Id, SetId] = useState();
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [RowId, SetRowId] = useState();
    const [deletePopupType, setDeletepopupType] = useState("");
    const [popupType, setpopupType] = useState("");
    const [Error, SetError] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    let today_date = (new Date()).toISOString().split('T')[0];
    const [filters, setFilters] = useState({
        from_date: today_date,
        to_date: today_date,
        search: ''
    })
    const debouncedSearchTerm = useDebouncedValue(filters.search, 500);
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }

    const columns = [
        {
            name: "GRN No",
            selector: "grn_no",
            sortable: true,
            width: "100px"
        },
        {
            name: "Vendor",
            cell: (row) => <div>{row.vendor.vendor_name}</div>,
            sortable: true,
            width: "100px"
        },
        {
            name: "Discount Amount",
            selector: "discount_amount",
            sortable: true,
        },
        {
            name: "Net Amount",
            selector: "net_amount",
            sortable: true,
        },
        {
            name: "Total Amount",
            selector: "total_amount",
            sortable: true
        },
        {
            name: "Total Quantity",
            selector: "total_qty",
            sortable: true
        },
        {
            name: "Created date",
            selector: "cdate",
            sortable: true,
            width: "200px"
        },
        {
            name: "Modified date",
            selector: "mdate",
            sortable: true,
            width: "200px"
        },
        {
            name: "Date of supply",
            selector: "date_of_supply",
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = () => {
                    navigate(`/dashboard/masters/grn/${row.id}/modify`, { state: { grn_data: row, vendor_data: row.vendor } })
                }
                const HandleExcel = (expenditure) => {
                    const url = `${axiosConfig.defaults.baseURL}accounts/download-grn/${expenditure.id}/`;
                    window.open(url, '_blank');
                };
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (row_id) => {
                    await axiosConfig.delete(`accounts/grn/${row_id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`accounts/grn/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("GRN revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                        setpopupType("error")
                        SetError(error)
                    }
                }
                return (
                    <>
                        {Id === row.id ? (
                            row.is_suspended ? (
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            ) : (
                                <>
                                    <div className="row" >
                                        <div className="col-3">
                                            <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                        </div>
                                        <div className="col-3">
                                            <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                        </div>
                                        <div className="col-3">
                                            <AiOutlineFileExcel className="dashboard-tabe-data-actions" onClick={() => HandleExcel(row)} />
                                        </div>
                                    </div>
                                </>
                            )
                        ) : (
                            <div className={`client-type ${row.is_suspended ? 'variable-background' : 'contract-background'}`}>
                                {row.is_suspended ? 'Suspended' : 'Active'}
                            </div>
                        )
                        }
                        {popupType != "" &&
                            <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} error={Error} />
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )
            }
        }
    ];

    const HandleNavigate = () => {
        navigate("/dashboard/masters/grn/add")
    }

    const HandleMouse = (row) => {
        SetId(row.id)
    }
    const HandleRowmouseLeave = () => {
        SetId()
    }

    const HandleChange = (e, filter) => {
        setFilters({ ...filters, [filter]: e.target.value })
    }

    const GetData = async () => {
        try {
            const grn_response = await axiosConfig.get(`/accounts/grn/?vendor=${selectedVendor ? selectedVendor.value : ''}&is_suspended=&date_of_supply_after=${filters.from_date}&date_of_supply_before=${filters.to_date}&search=${filters.search}`)
            if (vendors.length === 0) {
                const vendor_response = await axiosConfig.get("/accounts/vendors/?is_suspended=false")
                let vendor_options = vendor_response.data.results.map((vendor) => ({ label: vendor.vendor_name, value: vendor.id }))
                setVendors(vendor_options)
            }
            setGrns(grn_response.data.results)
            setTotalRows(grn_response.data.count)
        } catch (error) {
            console.log("error getting grns", error)
        }
    }

    useEffect(() => {
        GetData()
    }, [selectedVendor, filters.from_date, filters.to_date, debouncedSearchTerm])

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <BillsComponent />
                    <div className="childnav-content">
                        <div className="row">
                            <div className="col-3">
                                <input
                                    type="date"
                                    className="form-control"
                                    value={filters.from_date}
                                    onChange={(e) => HandleChange(e, "from_date")}
                                />
                            </div>
                            <div className="col-3">
                                <input
                                    type="date"
                                    className="form-control"
                                    value={filters.to_date}
                                    onChange={(e) => HandleChange(e, "to_date")}
                                />
                            </div>
                            <div className="col-4">
                                <div className="input-cat-with-icon pe-0">
                                    <span className="icon-product"><BiSearch /></span>
                                    <input type="text" className="input-control" placeholder="Search" name="search" onChange={(e) => HandleChange(e, "search")} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div class="cat-button"><div class="btn" onClick={() => HandleNavigate()}>+ Add New</div></div>
                            </div>
                        </div>
                        <Select
                            placeholder="Select Vendor"
                            options={vendors}
                            value={selectedVendor}
                            onChange={(selectedOption) => setSelectedVendor(selectedOption)}
                        />
                        <div className="table-responsive marginTop" style={{ zIndex: "0" }}>
                            <DataTable
                                columns={columns}
                                data={grns}
                                pagination
                                paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                // onSort={handleSort}
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}

export { GRN }