import React, { useEffect, useState, useRef } from "react";
import { AiOutlineSetting } from 'react-icons/ai';
import { TbReport, TbReportSearch } from 'react-icons/tb';
import { BiBarChartSquare, BiStore } from "react-icons/bi"
import { MdOutlineNotifications, MdOutlineGroups, MdLogout, MdHeadphones } from "react-icons/md"
import { RxBarChart } from "react-icons/rx";
import { RiCustomerServiceFill } from "react-icons/ri"
import "./DashboardLayout.css"
import sidebar from "./sidebar";
import { useNavigate } from "react-router";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ChartTable from "./Chart"
import { CgMenuBoxed, CgProfile } from "react-icons/cg"
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import axiosConfig from "../../Service/axiosConfig";
import { PiContactlessPaymentLight } from "react-icons/pi";
const DashboardLayout = ({ children }) => {
    const Navigate = useNavigate();
    const [smShow, setSmShow] = useState(false);
    const [childData, setChildData] = useState([])
    const [Toggle, SeToggle] = useState(true)
    const [ParentNav, SetParentNav] = useState("")
    const [ChildNavs, SetChildNavs] = useState("")
    const [UserData, SetUserData] = useState({})
    const scrollableContainerRef = useRef(null);
    const [siteSettings, setSiteSettings] = useState({})
    const handleNav = (event, url) => {
        let parentnav = event.currentTarget.dataset.parentnav
        localStorage.setItem('ParentNav', parentnav);
        localStorage.setItem("url", url)
        if (parentnav === "masters" || parentnav === "accounts") {
            setChildData(sidebar[parentnav])
            SetParentNav(parentnav)
        }
    }
    const AlertLogout = () => {
        setSmShow(!smShow)
    }
    const handleLogout = (event) => {
        localStorage.removeItem('token');
        setSmShow(false)
        Navigate("/")
    }
    const getUserData = async () => {
        const user_data = JSON.parse(localStorage.getItem('user_data'));
        SetUserData(user_data);
    }
    const getParentNav = async () => {
        let parentnav = localStorage.getItem('ParentNav')
        let childnavpath = localStorage.getItem('ChildNavs')
        if (parentnav === "masters" || parentnav === "accounts") {
            SetChildNavs(childnavpath)
            setChildData(sidebar[parentnav])
        }
    }
    const childNavClick = (childnavpath) => {
        localStorage.setItem('ChildNavs', childnavpath);
        SetChildNavs(childnavpath)
    }

    const GetSiteSettings = async () => {
        try {
            const responce = await axiosConfig.get(`/sitedata/site/`)
            console.log(responce.data, "site settings")
            if (responce.data.results.length > 0) {
                setSiteSettings(responce.data.results[0])
            }
        }
        catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getUserData();
        getParentNav();
        GetSiteSettings();
    }, [])
    let newDate = new Date()
    let Newmonth = newDate.getMonth()
    let previousMonth = Newmonth + 1;
    let year = newDate.getFullYear();
    if (previousMonth <= 9) {
        Newmonth = "0" + previousMonth
    }
    else {
        Newmonth = previousMonth
    }
    return (
        <>
            <div className="dashboard-layout">
                <header>
                    <div className="Dashboard-brand">
                        {/* <img className="DashBoard-Logo" src={require("./images/Logo.png")} alt="logo" /> */}
                        <img className="DashBoard-Logo" src={siteSettings.logo ? siteSettings.logo.image : ''} alt="logo" />
                    </div>
                    <div className="ms-auto menu-right">
                        <div className="online-toggle">
                            <p className="Class-order online-store">KPHB - 01</p>
                            <p className="Online-status">ONLINE SINCE 07:00 AM</p>
                        </div>
                        <div className="user-status">
                            <p className="user-online">ONLINE</p>
                            <img className="DashBoard-Photo" src={require("./images/Photo.png")} alt="userPhoto" />
                        </div>
                        <RiCustomerServiceFill className="DashBoard-Notification" />
                        <MdOutlineNotifications className="DashBoard-Notification" />
                        <MdLogout className="DashBoard-Notification" onClick={AlertLogout} />
                    </div>
                </header>
                <aside>
                    <nav className="sidebar-main">
                        {
                            UserData && UserData.role === "Admin" && (
                                <>
                                    <NavLink to="/dashboard" className={localStorage.getItem('ParentNav') == "Dashboard" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "Dashboard")} className={localStorage.getItem('ParentNav') == "Dashboard" ? "navitem active" : "navitem"} data-parentnav="Dashboard" >
                                        <RxBarChart className="sidebar-icon" />
                                        <p className="sidebar-name">Dashboard</p>
                                    </div></NavLink>
                                    <NavLink to="/dashboard/orders" className={localStorage.getItem('ParentNav') == "Appointments" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "Appointments")} className={localStorage.getItem('ParentNav') == "Appointments" ? "navitem active" : "navitem"} data-parentnav="Appointments" >
                                        <TbReport className="sidebar-icon" />
                                        <p className="sidebar-name">Orders</p>
                                    </div></NavLink>
                                    <NavLink to="/dashboard/reports" className={localStorage.getItem('ParentNav') == "Patients" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "Patients")} className={localStorage.getItem('ParentNav') == "Patients" ? "navitem active" : "navitem"} data-parentnav="Patients" >
                                        <BiBarChartSquare className="sidebar-icon" />
                                        <p className="sidebar-name">Reports</p>
                                    </div></NavLink>
                                    <NavLink to="/dashboard/masters/categories" className={localStorage.getItem('ParentNav') == "billing" ? "sidebar-icon acitve" : "sidebar-icon"} ><div onClick={(e) => handleNav(e, "billing")} className={localStorage.getItem('ParentNav') == "billing" ? "navitem active" : "navitem"} data-parentnav="billing" >
                                        <CgMenuBoxed className="sidebar-icon" />
                                        <p className="sidebar-name">Catlog</p>
                                    </div></NavLink>
                                    <NavLink to="/dashboard/masters/clients" className={localStorage.getItem('ParentNav') == "marketing" ? "sidebar-icon acitve" : "sidebar-icon"} ><div onClick={(e) => handleNav(e, "marketing")} className={localStorage.getItem('ParentNav') == "marketing" ? "navitem active" : "navitem"} data-parentnav="marketing" >
                                        <MdOutlineGroups className="sidebar-icon" />
                                        <p className="sidebar-name">Clients</p>
                                    </div></NavLink>
                                    <NavLink to={`/dashboard/masters/client_bills/?month=${year}-${Newmonth}`} className={localStorage.getItem('ParentNav') == "Bills" ? "sidebar-icon acitve" : "sidebar-icon"} ><div onClick={(e) => handleNav(e, "Bills")} className={localStorage.getItem('ParentNav') == "Bills" ? "navitem active" : "navitem"} data-parentnav="Bills" >
                                        <FaRegMoneyBillAlt className="sidebar-icon" />
                                        <p className="sidebar-name">Bills</p>
                                    </div></NavLink>
                                    <NavLink to="/dashboard/masters/sitesettings" className={localStorage.getItem('ParentNav') == "settings" ? "sidebar-icon acitve" : "sidebar-icon"} ><div onClick={(e) => handleNav(e, "settings")} className={localStorage.getItem('ParentNav') == "settings" ? "navitem active" : "navitem"} data-parentnav="settings" >
                                        <AiOutlineSetting className="sidebar-icon" />
                                        <p className="sidebar-name">Settings</p>
                                    </div></NavLink>
                                    {/* <NavLink to="/dashboard/masters/payment_types" className={localStorage.getItem('ParentNav') == "payments" ? "sidebar-icon acitve" : "sidebar-icon"} ><div onClick={(e) => handleNav(e, "payments")} className={localStorage.getItem('ParentNav') == "payments" ? "navitem active" : "navitem"} data-parentnav="payments" >
                                        <PiContactlessPaymentLight className="sidebar-icon" />
                                        <p className="sidebar-name">Payments</p>
                                    </div></NavLink> */}
                                    {/* <NavLink to="/dashboard/admin/profile" className={localStorage.getItem('ParentNav') == "reports" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "reports")} className={localStorage.getItem('ParentNav') == "reports" ? "navitem active" : "navitem"} data-parentnav="reports" >
                                        <BiStore className="sidebar-icon" />
                                        <p className="sidebar-name">Stores</p>
                                    </div></NavLink> */}
                                </>
                            )
                        }
                        {
                            UserData && UserData.role === "StoreManager" && (
                                <>
                                    <NavLink to="/dashboard/orders" className={localStorage.getItem('ParentNav') == "Appointments" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "Appointments")} className={localStorage.getItem('ParentNav') == "Appointments" ? "navitem active" : "navitem"} data-parentnav="Appointments" >
                                        <TbReport className="sidebar-icon" />
                                        <p className="sidebar-name">Orders</p>
                                    </div></NavLink>
                                    <NavLink to="/dashboard/reports" className={localStorage.getItem('ParentNav') == "Patients" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "Patients")} className={localStorage.getItem('ParentNav') == "Patients" ? "navitem active" : "navitem"} data-parentnav="Patients" >
                                        <TbReportSearch className="sidebar-icon" />
                                        <p className="sidebar-name">Reports</p>
                                    </div></NavLink>
                                    <NavLink to="/dashboard/masters/manageproducts" className={localStorage.getItem('ParentNav') == "billing" ? "sidebar-icon acitve" : "sidebar-icon"} ><div onClick={(e) => handleNav(e, "billing")} className={localStorage.getItem('ParentNav') == "billing" ? "navitem active" : "navitem"} data-parentnav="billing" >
                                        <CgMenuBoxed className="sidebar-icon" />
                                        <p className="sidebar-name">Menu</p>
                                    </div></NavLink>
                                    <NavLink to={`/dashboard/settings/stores/${UserData.store ? UserData.store : ""}/edit`} className={localStorage.getItem('ParentNav') == "reports" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "reports")} className={localStorage.getItem('ParentNav') == "reports" ? "navitem active" : "navitem"} data-parentnav="reports" >
                                        <CgProfile className="sidebar-icon" />
                                        <p className="sidebar-name">Profile</p>
                                    </div></NavLink>
                                </>
                            )
                        }
                    </nav>
                </aside>
                <div className="dashboard-content" id="scroll" ref={scrollableContainerRef}>
                    {children}
                </div>
            </div>
            <Modal
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body className="success-icon">
                    <h5 className="successmsg">Are you sure you want to logout ?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="primary" className="btn btn-primary" onClick={() => setSmShow(false)}>
                        Cancel
                    </button>
                    <button variant="primary" className="btn btn-primary" onClick={handleLogout}>
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export { DashboardLayout }
