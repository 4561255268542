import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../Components";
import { ReportsNav } from "../Components/navbar";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import axiosConfig from "../Service/axiosConfig";
import Select from 'react-select';
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import { downloadExcel } from 'react-export-table-to-excel';
import { useNavigate } from "react-router";

const ItemWiseSales = () => {

    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [selectedClient, setSelectedClient] = useState({ label: "All Stores", value: null });
    const [formData, setFormData] = useState({
        fromDate: (new Date()).toISOString().split('T')[0],
        toDate: (new Date()).toISOString().split('T')[0],
        store: ''
    });
    const [stats, setStats] = useState({});
    const [clients, setClients] = useState([]);
    const Navigate = useNavigate();

    const columns = [
        {
            name: 'S. No.',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Product',
            selector: 'product_name',
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: 'sale_quantity',
            sortable: true,
        },
        {
            name: 'Sale Value',
            selector: 'sale_value',
            sortable: true,
        },
        {
            name: 'Unit Price',
            selector: 'unit_sale_price',
            sortable: true,
        },
        {
            name: 'Product Mix',
            selector: 'product_mix',
            sortable: true,
        },
        {
            name: '',
            cell: (row) =>
                <a href={`/dashboard/masters/item_client_reports/${row.product_name.replace(/\//g, '-')}/${row.product_id}/product/${formData.fromDate}/from/${formData.toDate}/to`}>
                    Client Wise
                </a>
        },
        {
            name: '',
            cell: (row) =>
                <a href={`/dashboard/masters/date_wise_sales/${row.product_name.replace(/\//g, '-')}/${row.product_id}/product/${formData.fromDate}/from/${formData.toDate}/to`}>
                    Date Wise
                </a>
        },
    ];

    const multiSelectStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#c9dddc' : 'white', // Change background color for selected option
            color: state.isSelected ? '#1B7D78' : 'black', // Change text color for selected option
            ':hover': {
                backgroundColor: '#c9dddc', // Change background color on hover for options
            },
        }),
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const HandleSelect = (selectedOption) => {
        setSelectedClient(selectedOption);
        setFormData((prevFormData) => ({
            ...prevFormData,
            store: selectedOption.value
        }))
    };

    const CustomHeader = () => {
        return (
            <div className="rowEndCenter gap-10">
                <AiOutlineFileExcel
                    className="report-icons"
                    onClick={handleDownloadExcel}
                />
                <AiOutlineFilePdf
                    className="report-icons"
                    onClick={() => HandlePdf(data)}
                />
            </div>
        );
    };

    const header = [
        "S.No",
        "Product",
        "Quantity",
        "Sale Value",
        "Unit Price",
        "Product Mix",
    ];
    const body = data.map((res, index) => [index + 1, res.product_name, res.sale_quantity, res.sale_value, res.unit_sale_price, res.product_mix]);

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Client & Product Report",
            sheet: "Client & Product Report",
            tablePayload: {
                header: header,
                body: body,
            },
        });
    }

    const HandlePdf = (data) => {
        localStorage.setItem('pdf_data', JSON.stringify(data));
        window.open(`${window.location.origin}/print`, '_blank');
    }

    const handleSort = (column, direction) => {
        console.log(column, direction)
        axiosConfig.get(`/accounts/generate_item_sale_reports/?from_date=${formData.fromDate}&to_date=${formData.toDate}&ordering=${direction === "asc" ? column.name : `-${column.selector}`}`).then(res => {
            setData(res.data.data)
            setStats({
                "total_qty": res.data.total_qty,
                "total_amount": res.data.total_amount,
                "highest_sale_qty": res.data.highest_sale_qty,
                "lowest_sale_qty": res.data.lowest_sale_qty,
                "highest_sale": res.data.highest_sale,
                "lowest_sale": res.data.lowest_sale,
                "average_amount": res.data.average_amount,
                "average_qty": res.data.average_qty
            })
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };

    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/generate_item_sale_reports/?from_date=${formData.fromDate}&to_date=${formData.toDate}&page_size=${RowPerPage}&store=${formData.store}`)
            const data = response.data.data
            console.log("Itemwise Data", data)
            setData(data)
            setStats({
                "total_qty": response.data.total_qty,
                "total_amount": response.data.total_amount,
                "highest_sale_qty": response.data.highest_sale_qty,
                "lowest_sale_qty": response.data.lowest_sale_qty,
                "highest_sale": response.data.highest_sale,
                "lowest_sale": response.data.lowest_sale,
                "average_amount": response.data.average_amount,
                "average_qty": response.data.average_qty
            })
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const GetClients = async () => {
        try {
            const response = await axiosConfig.get(`/stores/stores/?page_size=100`)
            const clients = response.data.results
            console.log(clients)
            setClients(clients)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData();
        GetClients();
    }, [])

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <ReportsNav />
                    <div className="flex-1 reports-parent">
                        <div className="report-filters">
                            <Select
                                className="flex-1"
                                placeholder="All Stores"
                                options={[{ label: "All Stores", value: null }].concat(clients.map((client) => ({ label: client.name, value: client.id })))}
                                onChange={(selectedOption) => HandleSelect(selectedOption)}
                                styles={multiSelectStyles}
                            />
                            <div className="form-group flex-1">
                                <input
                                    className="form-control"
                                    type="date"
                                    name="fromDate"
                                    value={formData.fromDate}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group flex-1">
                                <input
                                    className="form-control"
                                    type="date"
                                    name="toDate"
                                    value={formData.toDate}
                                    onChange={handleChange}
                                />
                            </div>
                            <div style={{ minWidth: '143px', height: '37px' }} className="d-flex justify-content-center">
                                {loading ?
                                    <img src={require('../Components/FormParser/images/loader.gif')} className="loader-image" alt="loader" />
                                    :
                                    <button className="btn btn-primary" style={{ height: '37px' }} onClick={GetData}>Get Reports</button>
                                }
                            </div>
                        </div>
                        <div>
                            <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                <div className="col-3">Total Rs.</div>
                                <div className="col-3 f_14_400">{stats.total_amount}</div>
                                <div className="col-3">Total Qty</div>
                                <div className="col-3 f_14_400">{stats.total_qty}</div>
                            </div>
                        </div>
                        <div>
                            <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                <div className="col-3">Daily Avg</div>
                                <div className="col-3 f_14_400">{stats.average_amount}</div>
                                <div className="col-3">Daily Avg Qty</div>
                                <div className="col-3 f_14_400">{stats.average_qty}</div>
                            </div>
                        </div>
                        <div>
                            <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                <div className="col-3">Lowest Sale Rs.</div>
                                <div className="col-3 f_14_400">{stats.lowest_sale}</div>
                                <div className="col-3">Highest Qty</div>
                                <div className="col-3 f_14_400">{stats.highest_sale_qty}</div>
                            </div>
                        </div>
                        <div>
                            <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                <div className="col-3">Highest Sale Rs.</div>
                                <div className="col-3 f_14_400">{stats.highest_sale}</div>
                                <div className="col-3">Lowest Qty</div>
                                <div className="col-3 f_14_400">{stats.lowest_sale_qty}</div>
                            </div>
                        </div>
                        {/* <div className="printable-content"> */}
                        <DataTable columns={columns} data={data} pagination
                            className="printable-content"
                            title={<CustomHeader />}
                            // paginationTotalRows={totalRows}
                            // paginationPerPage={RowPerPage}
                            // paginationDefaultPage={currentPage}
                            // onChangePage={(page) => setCurrentPage(page)}
                            // paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            // onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            // paginationServer
                            customStyles={tableCustomStyles}
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                        {/* </div> */}
                    </div>
                </div>
            </DashboardLayout >
        </>
    )
}

export { ItemWiseSales }