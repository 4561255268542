import { DashboardLayout, PopupModal } from "../Components";
import { BillsComponent } from "../Components/navbar";
import Select from 'react-select';
import { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";

const Ledger = () => {

    const [clients, setClients] = useState([]);
    const [popupType, setpopupType] = useState("");
    const [error, SetError] = useState("")
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    function getCurrentMonth() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}`;

        return formattedDate;
    }

    const [filterData, setFilterData] = useState({
        period_from: getCurrentMonth(),
        period_to: getCurrentMonth(),
        client: ''
    })

    const columns = [
        {
            name: "S.No",
            selector: (row) => row.s_no,
            sortable: true,
            width: '90px'
        },
        {
            name: "Transaction No",
            selector: (row) => row.transaction_no,
            sortable: true,
            width: '150px'
        },
        {
            name: "Date",
            selector: (row) => row.date,
            sortable: true,
            width: '100px'
        },
        {
            name: "Transaction Detail",
            selector: (row) => row.transaction_detail,
            sortable: true,
            width:'300px',
            wrap: true,
            sortable: true,
        },
        {
            name: "Credit",
            selector: (row) => row.credit,
            sortable: true,
            right: true
        },
        {
            name: "Debit",
            selector: (row) => row.debit,
            sortable: true,
            right: true
        },
        {
            name: "Balance",
            selector: (row) => row.balance,
            sortable: true,
            right: true
        }
    ];

    const CustomHeader = () => {
        return (
            <div className="rowEndCenter gap-10">
                <AiOutlineFileExcel
                    className="report-icons"
                    onClick={handleExcel}
                />
                <AiOutlineFilePdf
                    className="report-icons"
                    onClick={handlePdf}
                />
            </div>
        );
    };
    const GetClients = async () => {
        try {
            const response = await axiosConfig.get(`/clients/clients/?page_size=1000&is_suspended=false`);
            setClients(response.data.results)
        } catch (error) {
            console.log(error);
        }
    }

    const handlePdf = async () => {
        try {
            if (!filterData.client) {
                alert("Please select a client");
                return;
            }
            const response = await axiosConfig.get(`accounts/sanry_ledger_pdf/`, {
                params: {
                    period_from: filterData.period_from || '',
                    period_to: filterData.period_to || '',
                    client: filterData.client || ''
                }
            });
            const pdfUrl = response.data?.pdf_url || `${axiosConfig.defaults.baseURL}accounts/sanry_ledger_pdf/?period_from=${filterData.period_from || ''}&period_to=${filterData.period_to || ''}&client=${filterData.client || ''}`;
            window.open(pdfUrl, '_blank');
        } catch (error) {
            console.error(error);
            setpopupType("error");
            SetError(error.response?.data?.error || "An error occurred while generating the PDF");
        }
    };

    const handleExcel = async () => {
        try {
            if (!filterData.client) {
                alert("Please select a client");
                return;
            }
            await axiosConfig.get(`accounts/sanry_ledger_csv/?client=${filterData.client || ''}&period_from=${filterData.period_from}&period_to=${filterData.period_to}`)
            const excelUrl = `${axiosConfig.defaults.baseURL}accounts/sanry_ledger_csv/?client=${filterData.client || ''}&period_from=${filterData.period_from}&period_to=${filterData.period_to}`;
            window.open(excelUrl, '_blank');
        } catch (error) {
            console.log(error)
            setpopupType("error");
            SetError(error.response?.data?.error || "An error occurred while generating the Excel");
        }
    }

    const GetSummary = async () => {
        setLoading(true)
        try {
            const response = await axiosConfig.get(`accounts/sanry_ledger/?client=${filterData.client || ''}&period_from=${filterData.period_from}&period_to=${filterData.period_to}`)
            setSummary(response.data);
            setTotalRows(response.data.no_of_transactions);
        } catch (error) {
            console.log(error)
            if(error.response?.data?.error === "No statements found for the given client and period."){
                setSummary(null)
            }else{
                setpopupType("error");
                SetError(error.response?.data?.error || "An error occurred while fetching data");
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        GetClients();
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <BillsComponent />
                    <div className="flex-1 reports-parent">
                        <div className="report-filters">
                            <Select
                                placeholder="Client"
                                className="flex-1"
                                options={clients.map((res) => ({ "value": res.id, "label": res.client_name }))}
                                onChange={(selectedOption) => setFilterData(prev => ({ ...prev, client: selectedOption.value }))}
                            />
                            <div className="form-group flex-1">
                                <input
                                    type="month"
                                    className="form-control"
                                    name="period_from"
                                    value={filterData.period_from}
                                    onChange={(e) => setFilterData(prev => ({ ...prev, period_from: e.target.value }))}
                                />
                            </div>
                            <div className="form-group flex-1">
                                <input
                                    type="month"
                                    className="form-control"
                                    name="period_to"
                                    value={filterData.period_to}
                                    onChange={(e) => setFilterData(prev => ({ ...prev, period_to: e.target.value }))}
                                />
                            </div>
                            <div style={{ minWidth: '143px', height: '37px' }} className="d-flex justify-content-center">
                                {loading ?
                                    <img src={require('../Components/FormParser/images/loader.gif')} className="loader-image" alt="loader" />
                                    :
                                    <button
                                        className="btn btn-primary"
                                        style={{ height: '37px' }}
                                        onClick={GetSummary}>
                                        Get Summary
                                    </button>
                                }
                            </div>
                        </div>
                        {summary &&
                            <div>
                                <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                    <div className="col-2">Client Name</div>
                                    <div className="col-4 f_14_400">{summary?.client_info?.name}</div>
                                    <div className="col-2">Statement period</div>
                                    <div className="col-4 f_14_400">{summary?.statement_period}</div>
                                </div>
                                <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                    <div className="col-2">Client Type</div>
                                    <div className="col-4 f_14_400">{summary?.client_info?.type}</div>
                                    <div className="col-2">Opening balance</div>
                                    <div className="col-4 f_14_400">{summary?.opening_balance}</div>
                                </div>
                                <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                    <div className="col-2">Email</div>
                                    <div className="col-4 f_14_400">{summary?.client_info?.email}</div>
                                    <div className="col-2">Total payments</div>
                                    <div className="col-4 f_14_400">{summary?.total_payments}</div>
                                </div>
                                <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                    <div className="col-2">Mobile No.</div>
                                    <div className="col-4 f_14_400">{summary?.client_info?.mobile}</div>
                                    <div className="col-2">Total billings</div>
                                    <div className="col-4 f_14_400">{summary?.total_purchases}</div>
                                </div>
                                <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                    <div className="col-2">Address</div>
                                    <div className="col-4 f_14_400">{summary?.client_info?.address}</div>
                                    <div className="col-2">No. of transactions</div>
                                    <div className="col-4 f_14_400">{summary?.no_of_transactions}</div>
                                </div>
                                <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                    <div className="col-2"></div>
                                    <div className="col-4 f_14_400"></div>
                                    <div className="col-2">Closing balance</div>
                                    <div className="col-4 f_14_400">{summary?.closing_balance}</div>
                                </div>
                            </div>
                        }
                        <DataTable columns={columns} data={summary?.transactions} pagination paginationTotalRows={totalRows}
                            title={<CustomHeader />}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            // onSort={handleSort}
                            paginationServer
                            customStyles={tableCustomStyles}
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                </div>
            </DashboardLayout>
            {popupType !== "" &&
                <PopupModal setpopupType={setpopupType} popupType={popupType} error={error} />
            }
        </>
    )
}

export { Ledger }