import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../Components";
import { ReportsNav } from "../Components/navbar";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import axiosConfig from "../Service/axiosConfig";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import { downloadExcel } from 'react-export-table-to-excel';
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

const DayWisePLReport = () => {

    const [data, setData] = useState([]);
    const [stores, setStores] = useState([]);
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState([]);
    const [stats, setStats] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const { date, product_name, product, from, to } = useParams()
    const [formData, setFormData] = useState({
        fromDate: date ? date : from ? from : (new Date()).toISOString().split('T')[0],
        toDate: date ? date : to ? to : (new Date()).toISOString().split('T')[0],
        store: '',
        product: product ? product : '',
        category: ''
    });
    const [nextPageUrl, setNextPageUrl] = useState('');
    const [search, setSearch] = useState('');
    const Navigate = useNavigate();

    const columns = [
        {
            name: 'S.No.',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
        },
        {
            name: ' Expenditure Total',
            selector: 'expenditure_total',
            sortable: true,
        },
        {
            name: 'GRN Total',
            selector: 'grn_total',
            sortable: true,
        },
        {
            name: 'Orders Total',
            selector: "orders_total",
            sortable: true,
        },
        {
            name: 'P&L',
            selector: "profit_or_loss",
            sortable: true,
        },
    ];

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSort = (column, direction) => {
        console.log(column, direction)
        // axiosConfig.get(`/accounts/orders/?orderstatus=Delivered&store=${formData.store ? formData.store : ''}&${selectedDateType === 'ordered_date' ? 'order_date_after' : 'expected_date_time_before'}=${formData.fromDate}&${selectedDateType === 'ordered_date' ? 'order_date_before' : 'expected_date_time_after'}=${formData.toDate}&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.name : `-${column.selector}`}`).then(res => {
        //     SetData(res.data.results)
        // setStats({
        //     "total_qty": response.data.total_qty,
        //     "total_amount": response.data.total_amount,
        //     "highest_sale_quantity": response.data.highest_sale_quantity,
        //     "lowest_sale_quantity": response.data.lowest_sale_quantity,
        //     "highest_sale_amount": response.data.highest_sale_amount,
        //     "lowest_sale_amount": response.data.lowest_sale_amount,
        //     "average_amount": response.data.average_amount,
        //     "average_qty": response.data.average_qty
        // })
        // }).catch(error => {
        //     console.log(error)
        // })
        // setCurrentPage(1);
    };

    const header = [
        "S.No",
        "Date",
        "Expenditure Total",
        "GRN Total",
        "Orders Total",
        "P&L",
    ];
    const body = data?.daywise_report?.map((res, index) => [index + 1, `${formData.fromDate}-${formData.toDate}`, res.expenditure_total, res.grn_total, res.orders_total, res.profit_or_loss]);

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Product-wise P&L Report",
            sheet: "Product-wise P&L Report",
            tablePayload: {
                header: header,
                body: body,
            },
        });
    }

    const HandlePdf = (data) => {
        localStorage.setItem('pdf_data', JSON.stringify(data));
        window.open(`${window.location.origin}/print`, '_blank');
    }
    const HandleSelect = (selectedOption, name) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: selectedOption.value
        }))
    };
    const handleSearch = (value) => setSearch(value)

    const GetFilterData = async () => {
        try {
            const store = await axiosConfig.get(`/stores/stores/?page_size=100`)
            const category = await axiosConfig.get(`/catlog/categories/?page_size=100&is_suspended=false`)
            const store_data = store.data.results;
            const category_data = category.data.results;
            setStores(store_data);
            setCategory(category_data);
        } catch (error) {
            console.log(error)
        }
    }

    const GetData = async () => {
        setLoading(true)
        try {
            const response = await axiosConfig.get(`accounts/daywise_profit_loss_reports/?from_date=${formData.fromDate}&to_date=${formData.toDate}`)
            const data = response.data
            console.log("Daywise Data", data)
            setLoading(false)
            setData(data)
        } catch (error) {
            console.log(error)
        }
    }
    const CustomHeader = () => {
        return (
            <div className="rowEndCenter gap-10">
                <AiOutlineFileExcel
                    className="report-icons"
                    onClick={handleDownloadExcel}
                />
                {/* <AiOutlineFilePdf
                    className="report-icons"
                    onClick={() => HandlePdf(data)}
                /> */}
            </div>
        );
    };

    useEffect(() => {
        GetData();
        GetFilterData();
    }, [])

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <ReportsNav />
                    <div className="flex-1 reports-parent">
                        <div className="row">
                            <div className=" col-5 form-group flex-1">
                                <input
                                    className="form-control"
                                    type="date"
                                    name="fromDate"
                                    value={formData.fromDate}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-5 form-group flex-1">
                                <input
                                    className="form-control"
                                    type="date"
                                    name="toDate"
                                    value={formData.toDate}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-2">
                                {loading ?
                                    <img src={require('../Components/FormParser/images/loader.gif')} className="loader-image" alt="loader" />
                                    :
                                    <button className="btn btn-primary" style={{ height: '37px' }} onClick={GetData}>Get Reports</button>
                                }
                            </div>
                        </div>
                        <div>
                            <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                <div className="col-3">Total Expenditure Rs.</div>
                                <div className="col-3 f_14_400">{data?.expenditure_total}</div>
                                <div className="col-3">Total  GRN Rs.</div>
                                <div className="col-3 f_14_400">{data?.grn_total}</div>
                            </div>
                        </div>
                        <div>
                            <div className=" row rowBetweenCenter f_14_500" style={{ margin: "16px" }}>
                                <div className="col-3">Order Total Rs.</div>
                                <div className="col-3 f_14_400">{data?.orders_total}</div>
                                <div className="col-3">Profit & Loss Rs.</div>
                                <div className="col-3 f_14_400">{data?.profit_or_loss}</div>
                            </div>
                        </div>
                        <DataTable
                            title={<CustomHeader />}
                            columns={columns}
                            data={data?.daywise_report}
                            pagination
                            paginationTotalRows={totalRows}
                            customStyles={tableCustomStyles}
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                </div>
            </DashboardLayout >
        </>
    )
}
export { DayWisePLReport }